<template>
    <div>
        <label v-if="label" :for="label" class="block text-sm font-medium leading-5 font-bold" :class="altStyling ? 'text-gray-100':'text-gray-900'">{{ label }}</label>
        <div class="mt-2 relative rounded-md shadow-sm border border-gray-200">
            <span :class="inputClasses">
                <input ref="input_d" :class="altStyling ? 'text-gray-100':''" maxlength="2" class="w-8 ml-2 py-3 text-xs sm:text-sm sm:py-4 px-1 bg-transparent" placeholder="dd" :id="label" type="text" :value="parsedValue.day" @input="updateValue" :aria-invalid="hasErrors ? 'true' : 'false'">
                <span class="text-gray-500">/</span>
                <input ref="input_m" :class="altStyling ? 'text-gray-100':''" maxlength="2" class="w-10 ml-2 py-3 text-xs sm:text-sm sm:py-4 px-1 bg-transparent" placeholder="mm" type="text" :value="parsedValue.month" @input="updateValue" :aria-invalid="hasErrors ? 'true' : 'false'">
                <span class="text-gray-500">/</span>
                <input ref="input_y" :class="altStyling ? 'text-gray-100':''" maxlength="4" class="w-16 mx-2 py-3 text-xs sm:text-sm sm:py-4 px-1 bg-transparent" placeholder="jjjj" type="text" :value="parsedValue.year" @input="updateValue" :aria-invalid="hasErrors ? 'true' : 'false'">
                <!-- v-on:keyup="moveField('input_y')" -->
            </span>
            <div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
        <template v-if="hasErrors">
            <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600 font-medium">{{ error }}</p>
        </template>
    </div>
</template>

<script>
export default {
    name: "TextInput",
    props: ['value', 'errors', 'label', 'altStyling'],
    computed:{
        hasErrors: function(){
            return this.errors && this.errors.length !== 0;
        },
        inputClasses: function () {
            if (this.hasErrors){
                return "form-input w-1/4 pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red sm:text-sm sm:leading-5";
            } else {
                return "form-input w-1/4 sm:text-sm sm:leading-5";
            }
        },
        parsedValue: function(){
            if(this.value === undefined || this.value === null){
                return {
                    day: "",
                    month: "",
                    year: "",
                }
            }
            const splitDate = this.value.split('-')
            return {
                day: splitDate[2],
                month: splitDate[1],
                year: splitDate[0]
            }
        }
    },
   
    methods:{
        updateValue: function (event){
            const date_string = this.$refs.input_y.value + "-" + this.$refs.input_m.value + "-" + this.$refs.input_d.value;
            this.$emit('update:value', date_string);
            this.clearErrors();
            this.focusField(event.target)
        },
        clearErrors(){
            this.$emit('update:errors', []);
        }, 
        focusField(target){
            const max_length = parseInt(target.attributes["maxlength"].value);
            const length = target.value.length;

            if(length >= max_length && target.nextSibling !== null && target.nextSibling.nextSibling !== null){
                target.nextSibling.nextSibling.focus();
            } 
            else if (length === 0 && target.previousSibling !== null && target.previousSibling.previousSibling !== null){
                target.previousSibling.previousSibling.focus();
            }
        }
    }
}
</script>

<style scoped>
    .input-wrapper{
        vertical-align: middle;
    }
</style>
